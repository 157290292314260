import { SingleProjectContainer } from "./styled";
import { SubTitle } from "components/styled/SubTitle";
import { Button } from "components/styled/Button";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { useLocation, useParams } from "react-router-dom";
import { IProject } from "types/project";
import DocumentMeta from "react-document-meta";
import { useCanonicalUrl } from "services/hooks/canonical";
import OneSlider from "components/parts/oneSlider";
import Request from "components/parts/request";
import { useTranslation } from "react-i18next";
import useDeviceType from "helpers";
import { IListItem } from "components/parts/news";
import { ProjectTitle } from "consts/global";
import { ReactComponent as Phone } from "assets/icons/phone.svg";
import HomeProjects from "components/parts/home/projects";

interface IProduct {
  product: IProject;
}

const SingleProject = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [gallery, setGallery] = useState([
    {
      url: "",
    },
  ]);
  const [offers, setOffers] = useState<IListItem[]>([]);
  const [showNumber, setShowNumber] = useState(false);
  const [request, setRequest] = useState<number>(0);
  const [active, setActive] = useState(false);
  const [data, setData] = useState<IProduct>();
  const [mainFeatures, setMainFeatures] = useState<any[]>([]);
  const [features, setFeatures] = useState<any[]>([]);

  const { id } = useParams();
  const used = location.pathname === "/used";

  useEffect(() => {
    if (id) {
      API.projects.getSingeProject(id, used ? "used" : "new").then((res) => {
        setData(res);
        setGallery(
          res.product["multiple-images"]?.length
            ? res.product["multiple-images"]
            : [res.product["main-image"]]
        );
        if (res.features?.length) {
          setMainFeatures([res.features[0]]);
          setFeatures([res.features[1]]);
        }

        API.projects
          .getSimilarProducts(res.product.id, used ? "used" : "new")
          .then((res) => {
            setOffers(
              res.map(
                (i: {
                  url: string;
                  slug: string;
                  img: string;
                  description: string;
                  price: string;
                  media: { url: string };
                }) => {
                  i.url = i.slug;
                  i.img = i.media?.url;
                  return i;
                }
              )
            );
          });
      });
    }
  }, [id, i18n.language, used]);

  const deviceType = useDeviceType();

  const url = useCanonicalUrl();
  const meta = {
    canonical: url,
  };

  return (
    <DocumentMeta
      {...meta}
      title={
        data?.product.meta_title
          ? `${data?.product.meta_title} | ${ProjectTitle}`
          : `${data?.product?.title} | ${ProjectTitle}`
      }
      description={data?.product.meta_description}
    >
      <SingleProjectContainer className="container">
        <Request open={request} close={() => setRequest(0)} />
        <div className="row">
          <div className="col-5 col-md-12">
            <div className="product-slider mb-600">
              <OneSlider title={data?.product?.title || ''} slider={gallery} />
            </div>
          </div>

          <div className="col-7 col-md-12">
            <h1>{data?.product?.title}</h1>

            <div className="description">
              {data?.product.short_description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.product.short_description,
                  }}
                />
              )}
            </div>

            <div className="display-flex flex-wrap call-container">
              <div className="button display-flex gap-200 align-items-center">
                <Phone />

                <span>
                  {showNumber ? (
                    <a href="tel:+0322994455"> +032 2 99 44 55 </a>
                  ) : (
                    "+032 2 999 ***"
                  )}
                </span>
                <span
                  className="color-yellow"
                  onClick={() => setShowNumber(!showNumber)}
                >
                  {t("viewNumber")}
                </span>
              </div>

              <Button
                size="lg"
                onClick={() =>
                  setRequest(data?.product.id ? data?.product.id : 0)
                }
              >
                {t("requestACall")}
              </Button>
            </div>

            {!!mainFeatures.length && (
              <div className="features mt-600 mb-600">
                {mainFeatures?.map((item) => (
                  <div key={item?.title}>
                    <div className={`features-list ${active ? "active" : ""}`}>
                      {item?.data.map(
                        (f: { key: string; value: string; id: number }) =>
                          f.key &&
                          f.value && (
                            <div key={f.id}>
                              <span>{f.key}</span>
                              <span>{f.value}</span>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {!!features.length && (
          <div>
            <SubTitle className="left">
              {t("informationAboutTheProduct")}
            </SubTitle>

            <div className="features mt-600 mb-600">
              {features.map((item) => (
                <div key={item?.title}>
                  <h4>{item?.title}</h4>

                  <div className={`features-list ${active ? "active" : ""}`}>
                    {item?.data.slice(0, !active ? 4 : item.data.length).map(
                      (f: { key: string; value: string; id: number }) =>
                        f.key &&
                        f.value && (
                          <div key={f.id}>
                            <span>{f.key}</span>
                            <span>{f.value}</span>
                          </div>
                        )
                    )}
                  </div>

                  {item?.data?.length > 4 && (
                    <Button
                      size="lg"
                      variant="light"
                      className={active ? "activeted" : ""}
                      onClick={() => setActive(!active)}
                    >
                      {!active ? t("seeMore") : t("seeLess")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M16.692 7.94254L10.442 14.1925C10.384 14.2506 10.3151 14.2967 10.2392 14.3282C10.1633 14.3597 10.082 14.3758 9.99986 14.3758C9.91772 14.3758 9.8364 14.3597 9.76052 14.3282C9.68465 14.2967 9.61572 14.2506 9.55767 14.1925L3.30767 7.94254C3.1904 7.82526 3.12451 7.6662 3.12451 7.50035C3.12451 7.3345 3.1904 7.17544 3.30767 7.05816C3.42495 6.94088 3.58401 6.875 3.74986 6.875C3.91571 6.875 4.07477 6.94088 4.19205 7.05816L9.99986 12.8668L15.8077 7.05816C15.8657 7.00009 15.9347 6.95403 16.0105 6.9226C16.0864 6.89117 16.1677 6.875 16.2499 6.875C16.332 6.875 16.4133 6.89117 16.4892 6.9226C16.565 6.95403 16.634 7.00009 16.692 7.05816C16.7501 7.11623 16.7962 7.18517 16.8276 7.26104C16.859 7.33691 16.8752 7.41823 16.8752 7.50035C16.8752 7.58247 16.859 7.66379 16.8276 7.73966C16.7962 7.81553 16.7501 7.88447 16.692 7.94254Z"
                          fill="#FCB026"
                        />
                      </svg>
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <SubTitle className="mb-400 left">{t("description")}</SubTitle>
        {data?.product.long_description && (
          <p
            dangerouslySetInnerHTML={{ __html: data?.product.long_description }}
          />
        )}
      </SingleProjectContainer>

      <section style={{ overflow: "hidden" }}>
        <HomeProjects
          projects={offers}
          discoverMore={deviceType === "mobile"}
        />
      </section>
    </DocumentMeta>
  );
};

export default SingleProject;
